import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartGroup } from '../../../interface/part-group';
import { PartSubgroup } from '../../../interface/part-subgroup';
import { GroupService } from '../../../service/group.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { SubgroupService } from '../../../service/subgroup.service';

@Component({
  selector: 'app-subgroup-edit',
  templateUrl: './subgroup-edit.component.html',
  styleUrl: './subgroup-edit.component.scss'
})
export class SubgroupEditComponent implements OnInit {

  form: FormGroup;
  subgroup: PartSubgroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      subgroup?: PartSubgroup,
      group?: PartGroup,
      partGroups?: PartGroup[]
    },
    private dialogRef: MatDialogRef<SubgroupEditComponent>,
    private formBuilder: FormBuilder,
    public groupService: GroupService,
    private subgroupService: SubgroupService,
    private layout: LayoutService,
    private snackbar: SnackbarService
  ) {
    if (data) {
      this.subgroup = data.subgroup
    }
  }

  displayGroup = (group?: PartGroup) => {
    return group && group.description ? group.description : "";
  }

  ngOnInit() {
    this.buildForm()
    // console.log(this.displayGroup(this.subgroup.group));
  }

  buildForm() {
    if (this.subgroup) {
      this.form = this.formBuilder.group({
        _id: this.subgroup._id,
        description: [this.subgroup.description, Validators.required],
        salePricePercentage: [this.subgroup.salePricePercentage, Validators.required],
        group: this.data.group || ''
      })
    } else {
      this.form = this.formBuilder.group({
        _id: '',
        description: ["", Validators.required],
        salePricePercentage: ["", Validators.required],
        group: this.data.group || ""
      })
    }
  }

  async onSubmit() {
    let subgroup: PartSubgroup = this.form.getRawValue();
    const isUpdate = Boolean(subgroup._id);
    try {
      this.layout.loader = true;
      if (isUpdate) {
        await this.subgroupService.update(subgroup)
      } else {
        const id = await this.subgroupService.register(subgroup);
        subgroup._id = id;
      }
      this.snackbar.success(isUpdate ? 'SUBGROUP.UPDATED' : 'SUBGROUP.ADDED');
    } catch (error) {
      console.error("Falha ao registrar subgrupo\n\n", error)
      this.snackbar.error(isUpdate ? 'SUBGROUP.NOT_UPDATED' : 'SUBGROUP.NOT_ADDED', null, error);
    } finally {
      this.layout.loader = false;
    }

    this.dialogRef.close(subgroup);
  }

  close() {
    this.dialogRef.close(null);
  }

}
